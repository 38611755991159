<template>
  <div class="no-login">
    <h3 class="text-white">请登录</h3>
    <p class="text-secondary text-10 mt-2">请登录后进行下一步操作</p>
    <div class="mt-2">
      <btn @click="login" class="btn btn-outline-primary">
        <fa :icon="['fab', 'steam']"></fa>
        <span class="ml-2">Steam 登录</span>
      </btn>
    </div>
  </div>
</template>

<script>
import { STEAM_LOGIN_URL } from '@/services/config'

export default {
  name: 'Login',
  computed: {
    redir() {
      return this.$route.query?.redir
    },
  },
  mounted() {},
  watch: {
    // $route(to) {
    //   if (this.redir) {
    //     localStorage.setItem('redir', this.redir)
    //   }
    // },
  },
  methods: {
    /**
     * 1. /offer
     * 2. /user/inventories
     * 3. /offer/:id/new, offer_token
     */
    login() {
      let url = STEAM_LOGIN_URL
      if (this.redir) {
        console.log(this.redir)
        let option = 0
        let offerToken = ''
        if (this.redir === '/offer') {
          option = 1
        }
        if (this.redir === '/user/inventories') {
          option = 2
        }
        if (this.redir.includes('/offer/') && this.redir.includes('/new')) {
          option = 3
          offerToken = this.redir.replace('/offer/', '').replace('/new', '')
        }
        if (option) {
          url += `?redir=${option}`
          if (offerToken) {
            url += `&offer_token=${offerToken}`
          }
        }
        url = encodeURI(url)
      }
      window.location = url
    },
  },
}
</script>

<style lang="scss" scoped>
.no-login {
  display: flex;
  padding: 2em;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .btn {
    font-size: 15px;
  }
}
</style>
